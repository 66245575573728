import Vue from 'vue';
import VueRouter from 'vue-router';
import { appCenterRouter } from './appCenterRouter'; 
import { commercialRouter } from './commercialRouter'; 
import { smartRouter } from './smartRouter'; 
import { systemRouter } from './systemRouter'; 
import { integralSystemRouter } from './integralSystemRouter'; 
import { employeeLearnRouter } from './employeeLearnRouter'; 
import { pointsMallRouter  } from './pointsMallRouter'; 
import { insuranceTrustRouter  } from './insuranceTrustRouter'; 
import { paySalaryRouter  } from './paySalaryRouter'; 

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/ERSIndex'
  },
  {
    path: '/ERSIndex',
    name: 'ERSIndex',
    redirect: '/smartEntry',
    component: () => import(/* webpackChunkName: "about" */ '../views/ERSIndex.vue'),
    meta: {
      // requiresAuth: true
    },
    children: [
      ...paySalaryRouter,
      ...appCenterRouter,
      ...commercialRouter,
      ...smartRouter,
      ...systemRouter,
      ...integralSystemRouter,
      ...employeeLearnRouter,
      ...pointsMallRouter,
      ...insuranceTrustRouter
    ]
  }
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
